<template>
    <div class="modal-login">
        <div class="login-content">
            <div class="content-title">
                <span class="gradient-white">{{ modalTab === 'forgot' ? 'RESET PASSWORD' : modalTab.toUpperCase() }}</span>
            </div>
            <div v-if="['login', 'register'].includes(modalTab)" class="content-auth">
                <LoginCredentials v-bind:tab="modalTab" />

            </div>
            <LoginForgot v-else />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import LoginCredentials from '@/components/login/LoginCredentials';
    import LoginForgot from '@/components/login/LoginForgot';

    export default {
        name: 'ModalLogin',
        components: {
            LoginCredentials,
            LoginForgot
        },  
        data() {
            return {
                modalTab: 'login'
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow'
            ]),
            modalSetTab(tab) {
                this.modalTab = tab;
            },
            modalTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => { this.modalsSetShow('Terms'); }, 300);
            }
        }
    }
</script>

<style scoped>
.modal-login {
    width: 700px;
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
    border: 1px solid hsla(0, 0%, 100%, .08);
    box-shadow: 0 4px 50px rgba(0,0,0,.5);
    background: #131313;
}

.modal-login .login-sidebar {
    width: 50%; 
    height: 100%;
    display: flex;
    justify-content: center;
}

.modal-login .sidebar-image {
    width: 100%;
    height: 100%;
    object-fit: contain; 
    border-radius: 15px 0 0 15px;
    margin-left: 10px;
}

.modal-login .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}
.modal-login .sidebar-separator {
    width: 1px; 
    height: 100%;
    background: #2e0101; 
    margin-left: 10px; 
}
.modal-login .content-title {
    text-align: center;
    font-size: 32px;
    font-weight: 900;
}

.modal-login .content-auth {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }
.modal-login .auth-seperator {
    width: 530px;
    position: relative;
    margin-top: 35px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #e0e0e0;
}

.modal-login .auth-seperator:before,
.modal-login .auth-seperator:after {
    content: '';
    width: calc(50% - 110px);
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: #ffffff;
    opacity: 0.5;
}

.modal-login .auth-seperator:before {
    left: 0;
}

.modal-login .auth-seperator:after {
    right: 0;
}

.modal-login button.button-credentials,
.modal-login button.button-roblox {
    width: 100%; 
    height: 54px;
    margin-top: 35px;
}

.modal-login button.button-credentials .button-inner,
.modal-login button.button-roblox .button-inner {
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    background: #222222;
    border-radius: 10px;
}

.modal-login button.button-roblox .button-inner {
    background: #141414;
}

.modal-login button.button-roblox .button-inner svg {
    margin-right: 14px;
    fill: #ffffff;
}

@media only screen and (max-width: 1030px) {
    .modal-login {
        width: calc(100vw - 20px);
    }
}

@media only screen and (max-width: 950px) {
    .modal-login .login-sidebar {
        display: none;
    }

    .modal-login .login-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 85px 15px 48px 15px;
    }
}

@media only screen and (max-width: 560px) {
    .modal-login .auth-seperator {
        width: 100%;
    }
}
</style>
