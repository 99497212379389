import { render, staticRenderFns } from "./Duels.vue?vue&type=template&id=09ccb824&scoped=true"
import script from "./Duels.vue?vue&type=script&lang=js"
export * from "./Duels.vue?vue&type=script&lang=js"
import style0 from "./Duels.vue?vue&type=style&index=0&id=09ccb824&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ccb824",
  null
  
)

export default component.exports