import { render, staticRenderFns } from "./AvatarImage.vue?vue&type=template&id=0cc2d6e1&scoped=true"
import script from "./AvatarImage.vue?vue&type=script&lang=js"
export * from "./AvatarImage.vue?vue&type=script&lang=js"
import style0 from "./AvatarImage.vue?vue&type=style&index=0&id=0cc2d6e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc2d6e1",
  null
  
)

export default component.exports