import { render, staticRenderFns } from "./UnboxHeaderBox.vue?vue&type=template&id=0963ab2e&scoped=true"
import script from "./UnboxHeaderBox.vue?vue&type=script&lang=js"
export * from "./UnboxHeaderBox.vue?vue&type=script&lang=js"
import style0 from "./UnboxHeaderBox.vue?vue&type=style&index=0&id=0963ab2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0963ab2e",
  null
  
)

export default component.exports