<template>
    <div class="roll-game-win" v-bind:class="{
        'win-high': multiplier / 100 >= 20,
        'win-mid': multiplier / 100 < 20 && multiplier / 100 >= 5,
        'win-low': multiplier / 100 < 5 && multiplier / 100 >= 2
    }">
        <div class="win-box">
            <div class="box-inner">
                <div class="inner-card">
                    <div class="card-inner">
                        <div class="inner-amount">
                            <img src="@/assets/img/icons/coin.svg" alt="icon" />
                            <div class="amount-value">
                                <span>{{rollFormatValue(winAmount).split('.')[0]}}</span>.{{rollFormatValue(winAmount).split('.')[1]}}
                            </div>
                        </div>
                        <div class="inner-multiplier">{{parseFloat(multiplier / 100).toFixed(2)}}x</div>
                        <div class="inner-win">
                            <div v-bind:style="{ 
                                'background-image': 'url(' + rollGetItem.image + ')',
                                'background-repeat': 'no-repeat',
                                'background-position': 'center',
                                'background-size': '110px auto'
                            }" class="win-image"></div>
                            {{ rollGetItem.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="text-green-gradient">YOUR BALANCE HAS BEEN CREDITED!</span>
    </div>
</template>

<script>
    export default {
        name: 'RollGameWin',
        props: [
            'winAmount', 
            'multiplier'
        ],
        data() {
            return {
                rollItems: {
                    1: [
                { name: 'Desert Eagle', image: 'https://static.wikia.nocookie.net/cswikia/images/1/1e/CS2_Desert_Eagle_Inventory.png' }, 
                { name: 'Knife', image: 'https://static.wikia.nocookie.net/cswikia/images/3/32/Knife_csgo.png' }, 
                { name: 'Dual Berettas', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3d/CS2_Dual_Berettas_Inventory.png' }, 
                { name: 'Five-SeveN', image: 'https://static.wikia.nocookie.net/cswikia/images/3/34/CS2_Five-SeveN_Inventory.png' }, 
                { name: 'Glock-18', image: 'https://static.wikia.nocookie.net/cswikia/images/0/06/CS2_Glock-18_Inventory.png' }, 
                { name: 'P228 Compact', image: 'https://static.wikia.nocookie.net/cswikia/images/7/77/P228hud_cz.png' },
                { name: 'K&M .45 Tactical', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f1/Usphud_cz.png' }, 
                { name: 'Leone 12 Gauge Super', image: 'https://static.wikia.nocookie.net/cswikia/images/d/d5/M3hud_cz.png' }, 
                { name: 'XM1014', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_XM1014_Inventory.png' }, 
                { name: 'MAC-10', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f0/CS2_MAC-10_Inventory.png' }
            ],
            2: [
                { name: 'MP9', image: 'https://static.wikia.nocookie.net/cswikia/images/5/5e/CS2_MP9_Inventory.png' }, 
                { name: 'UMP-45', image: 'https://static.wikia.nocookie.net/cswikia/images/d/db/CS2_UMP-45_Inventory.png' }, 
                { name: 'SG 553', image: 'https://static.wikia.nocookie.net/cswikia/images/c/c1/CS2_SG_553_Inventory.png' }, 
                { name: 'Galil AR', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_Galil_AR_Inventory.png' }, 
                { name: 'FAMAS', image: 'https://static.wikia.nocookie.net/cswikia/images/0/08/CS2_FAMAS_Inventory.png' }, 
                { name: 'AK-47', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0c/CS2_AK-47_Inventory.png' },
                { name: 'Scout', image: 'https://static.wikia.nocookie.net/cswikia/images/6/65/Scouthud_cz.png' }, 
                { name: 'AWP', image: 'https://static.wikia.nocookie.net/cswikia/images/5/50/CS2_AWP_Inventory.png' }, 
                { name: 'G3SG1', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ec/CS2_G3SG1_Inventory.png' }, 
                { name: 'SG 550', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3b/Sg550hud_cz.png' }
            ],
            3: [
                { name: 'M249', image: 'https://static.wikia.nocookie.net/cswikia/images/7/79/CS2_M249_Inventory.png' }, 
                { name: 'Negev', image: 'https://static.wikia.nocookie.net/cswikia/images/6/61/CS2_Negev_Inventory.png' }, 
                { name: 'USP', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f1/Usphud_cz.png' }, 
                { name: 'P2000', image: 'https://static.wikia.nocookie.net/cswikia/images/6/63/CS2_P2000_Inventory.png' }, 
                { name: 'MP5-SD', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f8/CS2_MP5-SD_Inventory.png' }, 
                { name: 'PP-Bizon', image: 'https://static.wikia.nocookie.net/cswikia/images/2/2e/CS2_PP-Bizon_Inventory.png' },
                { name: 'Tec-9', image: 'https://static.wikia.nocookie.net/cswikia/images/9/92/CS2_Tec-9_Inventory.png' }, 
                { name: 'R8 Revolver', image: 'https://static.wikia.nocookie.net/cswikia/images/9/94/CS2_R8_Revolver_Inventory.png' }, 
                { name: 'P90', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_P90_Inventory.png' }, 
                { name: 'MP7', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ee/CS2_MP7_Inventory.png' }
            ],
            4: [
                { name: 'M4A1-S', image: 'https://static.wikia.nocookie.net/cswikia/images/4/4d/CS2_M4A1-S_Inventory.png' }, 
                { name: 'M4A4', image: 'https://static.wikia.nocookie.net/cswikia/images/7/77/CS2_M4A4_Inventory.png' }, 
                { name: 'Glock-18', image: 'https://static.wikia.nocookie.net/cswikia/images/0/06/CS2_Glock-18_Inventory.png' }, 
                { name: 'USP-S', image: 'https://static.wikia.nocookie.net/cswikia/images/b/bb/CS2_USP-S_Inventory.png' }, 
                { name: 'AUG', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_AUG_Inventory.png' }, 
                { name: 'SG 553', image: 'https://static.wikia.nocookie.net/cswikia/images/c/c1/CS2_SG_553_Inventory.png' },
                { name: 'G3SG1', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ec/CS2_G3SG1_Inventory.png' }, 
                { name: 'AWP', image: 'https://static.wikia.nocookie.net/cswikia/images/5/50/CS2_AWP_Inventory.png' }, 
                { name: 'SCAR-20', image: 'https://static.wikia.nocookie.net/cswikia/images/2/27/CS2_SCAR-20_Inventory.png' }, 
                { name: 'MAG-7', image: 'https://static.wikia.nocookie.net/cswikia/images/a/ac/CS2_MAG-7_Inventory.png' }
            ],
            5: [
                { name: 'Galil AR', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_Galil_AR_Inventory.png' }, 
                { name: 'FAMAS', image: 'https://static.wikia.nocookie.net/cswikia/images/0/08/CS2_FAMAS_Inventory.png' }, 
                { name: 'P250', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3b/CS2_P250_Inventory.png' }, 
                { name: 'CZ75-Auto', image: 'https://static.wikia.nocookie.net/cswikia/images/9/90/CS2_CZ75-Auto_Inventory.png' }, 
                { name: 'Tec-9', image: 'https://static.wikia.nocookie.net/cswikia/images/9/92/CS2_Tec-9_Inventory.png' }, 
                { name: 'R8 Revolver', image: 'https://static.wikia.nocookie.net/cswikia/images/9/94/CS2_R8_Revolver_Inventory.png' },
                { name: 'Nova', image: 'https://static.wikia.nocookie.net/cswikia/images/b/b0/CS2_Nova_Inventory.png' }, 
                { name: 'Sawed-Off', image: 'https://static.wikia.nocookie.net/cswikia/images/4/47/CS2_Sawed-Off_Inventory.png' }, 
                { name: 'XM1014', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_XM1014_Inventory.png' }, 
                { name: 'M249', image: 'https://static.wikia.nocookie.net/cswikia/images/7/79/CS2_M249_Inventory.png' }
            ]
        }
    }
},
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            rollGetItem() {
                const multiplier = this.multiplier / 100;
                let item = this.rollItems[5][9];

                if(multiplier <= 2.5) {
                    item = this.rollItems[1][Math.round((multiplier - 1) / (1.5 / 9))];
                } else if(multiplier <= 5) {
                    item = this.rollItems[2][Math.round((multiplier - 2.5) / (2.5 / 9))];
                } else if(multiplier <= 50) {
                    item = this.rollItems[3][Math.round((multiplier - 5) / (45 / 9))];
                } else if(multiplier <= 100) {
                    item = this.rollItems[4][Math.round((multiplier - 50) / (50 / 9))];
                } else if(multiplier <= 1000) {
                    item = this.rollItems[5][Math.round((multiplier - 100) / (900 / 9))];
                }

                return item;
            }
        }
    }
</script>

<style scoped>
    .roll-game-win {
        width: 330px;
        height: 400px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 20px 15px;
        border-radius: 15px;
        background: #141414;
        border: 1px solid rgba(49, 49, 49, 0.5);
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5), inset 0px 0px 25px rgba(0, 0, 0, 0.35);
    }

    .roll-game-win span {
        font-size: 14px;
        font-weight: 800;
    }

    .roll-game-win .win-box {
        width: 100%;
        height: 325px;
        padding: 1px;
        border-radius: 8px;
        background: #161616;
    }

    .roll-game-win.win-high .win-box {
        background: linear-gradient(180deg, rgba(222, 68, 34, 0.5) 0%, rgba(222, 68, 34, 0.25) 50%, #de4422 100%);
    }

    .roll-game-win.win-mid .win-box {
        background: linear-gradient(180deg, rgba(255, 183, 3, 0.5) 0%, rgba(255, 183, 3, 0.25) 50%, #ffe603 100%);
    }

    .roll-game-win.win-low .win-box {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0.5) 0%, rgba(0, 255, 194, 0.25) 50%, #00ffc2 100%);
    }

    .roll-game-win .box-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #111111;
    }

    .roll-game-win.win-high .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(222, 35, 35, 0.75) -30%, rgba(222, 68, 34, 0.75) -16%, rgba(167, 43, 35, 0.431195) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(146, 25, 25, 0.25);
    }

    .roll-game-win.win-mid .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 183, 3, 0.75) -30%, rgba(255, 183, 3, 0.75) -16%, rgba(255, 183, 3, 0.43) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(255, 168, 0, 0.25);
    }

    .roll-game-win.win-low .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 255, 194, 0.75) -30%, rgba(0, 255, 194, 0.75) -16%, rgba(0, 255, 194, 0.26) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(35, 194, 99, 0.25);
    }

    .roll-game-win .inner-card {
        width: 195px;
        height: 275px;
        position: relative;
        flex-shrink: 0;
        margin-right: 4px;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    }

    .roll-game-win .inner-card::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #10283a;
border-radius: 10px;    }

    .roll-game-win.win-high .inner-card::before {
        background: linear-gradient(180deg, rgba(222, 68, 34, 0.5) 0%, rgba(222, 68, 34, 0.25) 50%, #de4422 100%);
    }

    .roll-game-win.win-mid .inner-card::before {
        background: linear-gradient(180deg, rgba(255, 183, 3, 0.5) 0%, rgba(255, 183, 3, 0.25) 50%, #ffe603 100%);
    }

    .roll-game-win.win-low .inner-card::before {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0.5) 0%, rgba(0, 255, 194, 0.25) 50%, #00ffc2 100%);
    }

    .roll-game-win .card-inner {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 1px;
        left: 1px;
        padding: 15px;
        background-color: #062137;
        border-radius: 10px;
        z-index: 1;
    }

    .roll-game-win.win-high .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(222, 35, 35, 0.75) -30%, rgba(222, 68, 34, 0.75) -16%, rgba(167, 43, 35, 0.431195) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(146, 25, 25, 0.25);
    }

    .roll-game-win.win-mid .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 183, 3, 0.75) -30%, rgba(255, 183, 3, 0.75) -16%, rgba(255, 183, 3, 0.43) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(255, 168, 0, 0.25);
    }

    .roll-game-win.win-low .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 255, 194, 0.75) -30%, rgba(0, 255, 194, 0.75) -16%, rgba(0, 255, 194, 0.26) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(35, 194, 99, 0.25);
    }

    .roll-game-win .inner-amount {
        display: flex;
        align-items: center;
    }

    .roll-game-win .inner-amount img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }

    .roll-game-win .amount-value {
        font-size: 12px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .roll-game-win .amount-value span {
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    .roll-game-win .inner-multiplier {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-game-win .inner-win {
        height: calc(100% - 57px);
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-game-win .win-image {
        width: 100%;
        height: 110px;
    }
</style>
