<template>
    <button v-on:click="modalsSetShow('Login')" class="auth-button">
        <div class="button-inner">
            <IconRoblox />
            SIGN IN
        </div>
    </button>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconRoblox from '@/components/icons/IconRoblox';

    export default {
        name: 'AuthButton',
        components: {
            IconRoblox
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    }
</script>

<style scoped>
    button.auth-button {
        height: 50px;
    }

    button.auth-button .button-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 22px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        background: #222222;
        border-radius: 10px;
        transition: all 0.3s ease;
    }

    button.auth-button .button-inner svg {
        margin-right: 11px;
        fill: #ffffff;
    }
</style>
