import { render, staticRenderFns } from "./ModalTerms.vue?vue&type=template&id=45f7abf7&scoped=true"
import script from "./ModalTerms.vue?vue&type=script&lang=js"
export * from "./ModalTerms.vue?vue&type=script&lang=js"
import style0 from "./ModalTerms.vue?vue&type=style&index=0&id=45f7abf7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f7abf7",
  null
  
)

export default component.exports