<template>
    
    <div class="navbar-cashier">
      <button v-on:click="modalsSetShow('Cashier')" class="button-cashier">
        <div class="button-inner">
          <IconCashier />
          Wallet
        </div>
      </button>
      <div class="cashier-balance">
        <div class="balance-inner">
          <img src="@/assets/img/icons/coin.svg" alt="icon" />
          <AmountNumber v-bind:amount="authUser.user.balance" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import AmountNumber from '@/components/AmountNumber';
  import IconCashier from '@/components/icons/IconCashier';
  
  export default {
    name: 'NavbarCashier',
    components: {
      AmountNumber,
      IconCashier,
    },
    methods: {
      ...mapActions(['modalsSetShow']),
    },
    computed: {
      ...mapGetters(['authUser']),
      navbarGetBalance() {
        return parseFloat(Math.floor(this.authUser.user.balance / 10) / 100)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    },
  };
  </script>
  
  <style scoped>
  .navbar-cashier {
    display: flex;
    align-items: center;
  }
  
  .navbar-cashier .button-cashier {
    height: 50px;
    position: relative;
    margin-right: 15px; /* Add some margin between the wallet button and cashier */
    padding: 1px;
  }
  
  .navbar-cashier .button-cashier .button-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px 0 14px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    background-color: #0f0f0f;
    border: 1px solid #414141;
    border-radius: 10px;
    gap: 6px;
  }
  
  .navbar-cashier .button-cashier .button-inner svg {
    fill: #ffffff;
    margin-right: 8px; /* Add margin between the text and the SVG icon */
  }
  
  .navbar-cashier .cashier-balance {
    width: 190px;
    height: 50px;
    position: relative;
    padding: 1px;
    
  }
  
  .navbar-cashier .balance-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    background-color: #0f0f0f;
    border: 1px solid #414141;
    border-radius: 10px;
    z-index: 1;
  }
  
  .navbar-cashier .balance-inner img {
    width: 24px;
    height: 24px;
  }
  
  .navbar-cashier .inner-value {
    font-size: 12px;
    font-weight: 600;
    color: #c1c1c1;
  }
  
  .navbar-cashier .inner-value span {
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
  }
  
  @media only screen and (max-width: 1175px) {
    .navbar-cashier .button-cashier {
      margin-left: 15px;
      height: 38px;
    }
  
    .navbar-cashier .button-cashier .button-inner {
      padding: 0 13px;
    }
  
    .navbar-cashier .button-cashier .button-inner svg {
      width: 14px;
    }
  
    .navbar-cashier .button-cashier .button-inner svg {
      margin-right: 0;
    }
  
    .navbar-cashier .button-cashier .button-inner span {
      display: none;
    }
  
    .navbar-cashier .cashier-balance {
      width: auto;
      height: 38px;
    }
  
    .navbar-cashier .cashier-balance button.button-free {
      height: 16px;
      top: -8px;
    }
  
    .navbar-cashier .cashier-balance button.button-free .button-inner {
      padding: 0 6px;
      font-size: 8px;
    }
  
    .navbar-cashier .balance-inner {
      padding: 0 12px 0 5px;
    }
  
    .navbar-cashier .balance-inner img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  </style>
  