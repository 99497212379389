<template>
    <div class="roll-card" v-bind:class="{
        'card-high': multiplier >= rollGetMinBetMultiplier && multiplier >= 20,
        'card-mid': multiplier >= rollGetMinBetMultiplier && multiplier < 20 && multiplier >= 5,
        'card-low': multiplier >= rollGetMinBetMultiplier && multiplier < 5 && multiplier >= 2
    }">
        <div class="card-inner">
            <div class="inner-amount">
                <img src="@/assets/img/icons/coin.svg" alt="icon" />
                <div class="amount-value">
                    <span>{{rollFormatValue(rollGetPotential).split('.')[0]}}</span>.{{rollFormatValue(rollGetPotential).split('.')[1]}}
                </div>
            </div>
            <div class="inner-multiplier">{{parseFloat(multiplier).toFixed(2)}}x</div>
            <div v-if="multiplier >= rollGetMinBetMultiplier" class="inner-win">
                <div v-bind:style="{ 
                    'background-image': 'url(' + rollGetItem.image + ')',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center',
                    'background-size': '80px auto'
                }" class="win-image"></div>
                {{ rollGetItem.name }}
            </div>
            <div v-else class="inner-lose">
                <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M52 0C23.3285 0 0 23.3285 0 52C0 80.6715 23.3285 104 52 104C80.6715 104 104 80.6715 104 52C104 23.3285 80.6715 0 52 0ZM26 39C26 35.4185 28.9185 32.5 32.5 32.5C36.0815 32.5 39 35.4185 39 39C39 42.5815 36.0815 45.5 32.5 45.5C28.9185 45.5 26 42.5815 26 39ZM74.9775 79.118C74.3405 79.755 73.5085 80.067 72.6765 80.067C71.8445 80.067 71.0125 79.7485 70.3755 79.118C60.5475 69.29 43.4265 69.29 33.605 79.118C32.3375 80.3855 30.277 80.3855 29.0095 79.118C27.742 77.8505 27.742 75.79 29.0095 74.5225C35.1585 68.3865 43.316 65 52 65C60.684 65 68.8415 68.3865 74.9775 74.5225C76.245 75.79 76.245 77.8505 74.9775 79.118ZM71.5 45.5C67.9185 45.5 65 42.5815 65 39C65 35.4185 67.9185 32.5 71.5 32.5C75.0815 32.5 78 35.4185 78 39C78 42.5815 75.0815 45.5 71.5 45.5Z" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'RollCard',
        props: ['multiplier'],
        data() {
            return {
                rollItems: {
            1: [
                { name: 'Desert Eagle', image: 'https://static.wikia.nocookie.net/cswikia/images/1/1e/CS2_Desert_Eagle_Inventory.png' }, 
                { name: 'Knife', image: 'https://static.wikia.nocookie.net/cswikia/images/3/32/Knife_csgo.png' }, 
                { name: 'Dual Berettas', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3d/CS2_Dual_Berettas_Inventory.png' }, 
                { name: 'Five-SeveN', image: 'https://static.wikia.nocookie.net/cswikia/images/3/34/CS2_Five-SeveN_Inventory.png' }, 
                { name: 'Glock-18', image: 'https://static.wikia.nocookie.net/cswikia/images/0/06/CS2_Glock-18_Inventory.png' }, 
                { name: 'P228 Compact', image: 'https://static.wikia.nocookie.net/cswikia/images/7/77/P228hud_cz.png' },
                { name: 'K&M .45 Tactical', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f1/Usphud_cz.png' }, 
                { name: 'Leone 12 Gauge Super', image: 'https://static.wikia.nocookie.net/cswikia/images/d/d5/M3hud_cz.png' }, 
                { name: 'XM1014', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_XM1014_Inventory.png' }, 
                { name: 'MAC-10', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f0/CS2_MAC-10_Inventory.png' }
            ],
            2: [
                { name: 'MP9', image: 'https://static.wikia.nocookie.net/cswikia/images/5/5e/CS2_MP9_Inventory.png' }, 
                { name: 'UMP-45', image: 'https://static.wikia.nocookie.net/cswikia/images/d/db/CS2_UMP-45_Inventory.png' }, 
                { name: 'SG 553', image: 'https://static.wikia.nocookie.net/cswikia/images/c/c1/CS2_SG_553_Inventory.png' }, 
                { name: 'Galil AR', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_Galil_AR_Inventory.png' }, 
                { name: 'FAMAS', image: 'https://static.wikia.nocookie.net/cswikia/images/0/08/CS2_FAMAS_Inventory.png' }, 
                { name: 'AK-47', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0c/CS2_AK-47_Inventory.png' },
                { name: 'Scout', image: 'https://static.wikia.nocookie.net/cswikia/images/6/65/Scouthud_cz.png' }, 
                { name: 'AWP', image: 'https://static.wikia.nocookie.net/cswikia/images/5/50/CS2_AWP_Inventory.png' }, 
                { name: 'G3SG1', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ec/CS2_G3SG1_Inventory.png' }, 
                { name: 'SG 550', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3b/Sg550hud_cz.png' }
            ],
            3: [
                { name: 'M249', image: 'https://static.wikia.nocookie.net/cswikia/images/7/79/CS2_M249_Inventory.png' }, 
                { name: 'Negev', image: 'https://static.wikia.nocookie.net/cswikia/images/6/61/CS2_Negev_Inventory.png' }, 
                { name: 'USP', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f1/Usphud_cz.png' }, 
                { name: 'P2000', image: 'https://static.wikia.nocookie.net/cswikia/images/6/63/CS2_P2000_Inventory.png' }, 
                { name: 'MP5-SD', image: 'https://static.wikia.nocookie.net/cswikia/images/f/f8/CS2_MP5-SD_Inventory.png' }, 
                { name: 'PP-Bizon', image: 'https://static.wikia.nocookie.net/cswikia/images/2/2e/CS2_PP-Bizon_Inventory.png' },
                { name: 'Tec-9', image: 'https://static.wikia.nocookie.net/cswikia/images/9/92/CS2_Tec-9_Inventory.png' }, 
                { name: 'R8 Revolver', image: 'https://static.wikia.nocookie.net/cswikia/images/9/94/CS2_R8_Revolver_Inventory.png' }, 
                { name: 'P90', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_P90_Inventory.png' }, 
                { name: 'MP7', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ee/CS2_MP7_Inventory.png' }
            ],
            4: [
                { name: 'M4A1-S', image: 'https://static.wikia.nocookie.net/cswikia/images/4/4d/CS2_M4A1-S_Inventory.png' }, 
                { name: 'M4A4', image: 'https://static.wikia.nocookie.net/cswikia/images/7/77/CS2_M4A4_Inventory.png' }, 
                { name: 'Glock-18', image: 'https://static.wikia.nocookie.net/cswikia/images/0/06/CS2_Glock-18_Inventory.png' }, 
                { name: 'USP-S', image: 'https://static.wikia.nocookie.net/cswikia/images/b/bb/CS2_USP-S_Inventory.png' }, 
                { name: 'AUG', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_AUG_Inventory.png' }, 
                { name: 'SG 553', image: 'https://static.wikia.nocookie.net/cswikia/images/c/c1/CS2_SG_553_Inventory.png' },
                { name: 'G3SG1', image: 'https://static.wikia.nocookie.net/cswikia/images/e/ec/CS2_G3SG1_Inventory.png' }, 
                { name: 'AWP', image: 'https://static.wikia.nocookie.net/cswikia/images/5/50/CS2_AWP_Inventory.png' }, 
                { name: 'SCAR-20', image: 'https://static.wikia.nocookie.net/cswikia/images/2/27/CS2_SCAR-20_Inventory.png' }, 
                { name: 'MAG-7', image: 'https://static.wikia.nocookie.net/cswikia/images/a/ac/CS2_MAG-7_Inventory.png' }
            ],
            5: [
                { name: 'Galil AR', image: 'https://static.wikia.nocookie.net/cswikia/images/0/0a/CS2_Galil_AR_Inventory.png' }, 
                { name: 'FAMAS', image: 'https://static.wikia.nocookie.net/cswikia/images/0/08/CS2_FAMAS_Inventory.png' }, 
                { name: 'P250', image: 'https://static.wikia.nocookie.net/cswikia/images/3/3b/CS2_P250_Inventory.png' }, 
                { name: 'CZ75-Auto', image: 'https://static.wikia.nocookie.net/cswikia/images/9/90/CS2_CZ75-Auto_Inventory.png' }, 
                { name: 'Tec-9', image: 'https://static.wikia.nocookie.net/cswikia/images/9/92/CS2_Tec-9_Inventory.png' }, 
                { name: 'R8 Revolver', image: 'https://static.wikia.nocookie.net/cswikia/images/9/94/CS2_R8_Revolver_Inventory.png' },
                { name: 'Nova', image: 'https://static.wikia.nocookie.net/cswikia/images/b/b0/CS2_Nova_Inventory.png' }, 
                { name: 'Sawed-Off', image: 'https://static.wikia.nocookie.net/cswikia/images/4/47/CS2_Sawed-Off_Inventory.png' }, 
                { name: 'XM1014', image: 'https://static.wikia.nocookie.net/cswikia/images/c/ce/CS2_XM1014_Inventory.png' }, 
                { name: 'M249', image: 'https://static.wikia.nocookie.net/cswikia/images/7/79/CS2_M249_Inventory.png' }
            ]
        }
    }
},
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString();
            }
        },
        computed: {
            ...mapGetters([
                'authUser', 
                'rollGame', 
                'rollBets'
            ]),
            rollGetItem() {
                let item = this.rollItems[5][9];

                if(this.multiplier <= 2.5) {
                    item = this.rollItems[1][Math.round((this.multiplier - 1) / (1.5 / 9))];
                } else if(this.multiplier <= 5) {
                    item = this.rollItems[2][Math.round((this.multiplier - 2.5) / (2.5 / 9))];
                } else if(this.multiplier <= 50) {
                    item = this.rollItems[3][Math.round((this.multiplier - 5) / (45 / 9))];
                } else if(this.multiplier <= 100) {
                    item = this.rollItems[4][Math.round((this.multiplier - 50) / (50 / 9))];
                } else if(this.multiplier <= 1000) {
                    item = this.rollItems[5][Math.round((this.multiplier - 100) / (900 / 9))];
                }

                return item;
            },
            rollGetPotential() {
                let potential = 0;

                if(this.authUser.user !== null) {
                    for(const bet of this.rollBets.filter((element) => element.user._id === this.authUser.user._id)) {
                        if(this.multiplier >= (bet.multiplier / 100)) {
                            potential = potential + Math.floor(bet.amount * (bet.multiplier / 100));
                        }
                    }
                }

                return potential;
            },
            rollGetMinBetMultiplier() {
                let multiplier = 0;

                if(this.authUser.user !== null) {
                    for(const bet of this.rollBets.filter((element) => element.user._id === this.authUser.user._id)) {
                        if(multiplier === 0 || bet.multiplier < multiplier) { multiplier = bet.multiplier; }
                    }
                }

                return multiplier / 100;
            }
        }
    }
</script>

<style scoped>
    .roll-card {
        width: 139px;
        height: 100%;
        position: relative;
        flex-shrink: 0;
        margin-right: 4px;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    }

    .roll-card:last-of-type {
        margin-right: 0;
    }

    .roll-card::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #636363;
        border-radius: 10px;
        }

    .roll-card.card-high::before {
        background: linear-gradient(180deg, rgba(222, 68, 34, 0.5) 0%, rgba(222, 68, 34, 0.25) 50%, #de4422 100%);
    }

    .roll-card.card-mid::before {
        background: linear-gradient(180deg, rgba(255, 183, 3, 0.5) 0%, rgba(255, 183, 3, 0.25) 50%, #ffe603 100%);
    }

    .roll-card.card-low::before {
        background: linear-gradient(180deg, rgba(0, 255, 194, 0.5) 0%, rgba(0, 255, 194, 0.25) 50%, #00ffc2 100%);
    }

    .roll-card .card-inner {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 1px;
        left: 1px;
        padding: 15px;
        background-color: #111111;
        border-radius: 10px;
        z-index: 1;
    }

    .roll-card.card-high .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(222, 35, 35, 0.75) -30%, rgba(222, 68, 34, 0.75) -16%, rgba(167, 43, 35, 0.431195) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(146, 25, 25, 0.25);
    }

    .roll-card.card-mid .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 183, 3, 0.75) -30%, rgba(255, 183, 3, 0.75) -16%, rgba(255, 183, 3, 0.43) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(255, 168, 0, 0.25);
    }

    .roll-card.card-low .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 255, 194, 0.75) -30%, rgba(0, 255, 194, 0.75) -16%, rgba(0, 255, 194, 0.26) 30%, rgba(0, 0, 0, 0) 100%), #062137;
        box-shadow: inset 0px 0px 75px rgba(35, 194, 99, 0.25);
    }

    .roll-card .inner-amount {
        display: flex;
        align-items: center;
    }

    .roll-card .inner-amount img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
    }

    .roll-card .amount-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .roll-card .amount-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .roll-card .inner-multiplier {
        margin-top: 2px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-card .inner-win {
        height: calc(100% - 46px);
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-card .win-image {
        width: 100%;
        height: 80px;
    }

    .roll-card .inner-lose {
        margin-top: 14px;
    }

    .roll-card .inner-lose svg {
        fill: #292929;
    }
</style>
